import {
	Disclosure,
	Menu,
	RadioGroup,
	Switch,
	Transition,
} from "@headlessui/react"
import React, { Fragment, useEffect, useState } from "react"
import { graphql, navigate, useStaticQuery } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import axios from "axios"
import { useAuth0 } from "@auth0/auth0-react"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ")
}

const ScheduleType = {
	ANNUAL: 408740000,
	STUDENT: 408740001,
	MONTHLY: 408740002,
	LIFE: 408740003,
}

const MembershipTypes = {
	FELLOW: 408740005,
}

const MembershipItem = ({ data: { memberPlans, allMemberPlans } }) => {
	const [plans, setPlans] = useState([])
	const [loaded, setLoaded] = useState(false)
	const [selected, setSelected] = useState()
	const [alreadyPurchased, setAlreadyPurchased] = useState()
	const { getIdTokenClaims } = useAuth0()

	useEffect(async () => {
		if (!loaded) {
			setPlans(await getAllPlans())
			setLoaded(true)
		}
		if (!alreadyPurchased) {
			await checkMembership()
		}
	}, [alreadyPurchased, selected])

	const checkMembership = async () => {
		try {
			const tokenClaims = await getIdTokenClaims()
			const response = await axios.get(
				`${process.env.GATSBY_API_BASE_URL}/Member/CheckMember`,
				{
					headers: {
						Authorization: `Bearer ${tokenClaims.__raw}`,
					},
				}
			)

			const { data, status } = response

			if (status) {
				if (data.isMember) {
					setAlreadyPurchased(true)
				} else {
					setAlreadyPurchased(false)
				}
			}
		} catch (e) {
			// console.error(e.message)
			setAlreadyPurchased(false)
		}
	}

	const getAllPlans = async () => {
		let planArr = []

		await allMemberPlans.nodes.forEach(plan => {
			if (plan.name.includes(memberPlans.name)) {
				if (plan.scheduleType === ScheduleType.MONTHLY) {
					plan.monthly = true
				}
				planArr.push(plan)
			}
		})
		setSelected(planArr[0])
		return planArr
	}

	const handleSubmit = e => {
		e.preventDefault()
		if (!alreadyPurchased) {
			navigate(`/create-order?membershipProductId=${selected.productId}`, {
				replace: true,
			})
		}
	}

	return (
		<Layout>
			<Seo title={`IPWEA Member Plan - ${memberPlans.name}`} />
			<Header title={memberPlans.name} />
			<div className="pt-5 pb-5 mx-10 lg:mx-20 xl:mx-28">
				<p dangerouslySetInnerHTML={{ __html: memberPlans.description }}></p>
			</div>
			{+memberPlans.membershipType !== MembershipTypes.FELLOW ? (
				<section aria-labelledby="plan-heading">
					<form onSubmit={handleSubmit}>
						<div className="shadow sm:rounded-md sm:overflow-hidden bg-white md:mx-10 lg:mx-16">
							<div className="py-6 px-4 space-y-6 sm:p-6 ">
								<div>
									<h2
										id="plan-heading"
										className="text-base leading-6 font-medium text-gray-400"
									>
										Select a membership plan
									</h2>
								</div>

								<RadioGroup value={selected} onChange={setSelected}>
									<div className="relative bg-white rounded -space-y-px">
										{plans.map((plan, planIdx) => (
											<RadioGroup.Option
												key={plan.name}
												value={plan}
												className={({ checked }) =>
													classNames(
														planIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
														planIdx === plans.length - 1
															? "rounded-bl-md rounded-br-md"
															: "",
														checked
															? "bg-indigo-50 border-indigo-200 z-10"
															: "border-gray-200",
														"border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none"
													)
												}
											>
												{({ active, checked }) => (
													<>
														<div className="flex items-center text-sm">
															<span
																className={classNames(
																	checked
																		? "bg-indigo-600 border-transparent"
																		: "bg-white border-gray-300",
																	active
																		? "ring-2 ring-offset-2 ring-indigo-500"
																		: "",
																	"h-4 w-4 rounded-full border flex items-center justify-center"
																)}
																aria-hidden="true"
															>
																<span className="rounded-full bg-white w-1.5 h-1.5" />
															</span>
															<RadioGroup.Label
																as="span"
																className={classNames(
																	checked ? "text-indigo-900" : "text-gray-900",
																	"ml-3 font-medium"
																)}
															>
																{plan.name}
															</RadioGroup.Label>
														</div>
														<RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
															{plan.monthly ? (
																<>
																	<span
																		className={classNames(
																			checked
																				? "text-indigo-900"
																				: "text-gray-900",
																			"font-medium"
																		)}
																	>
																		${plan.standardPrice} / mo
																	</span>{" "}
																	<span
																		className={
																			checked
																				? "text-indigo-700"
																				: "text-gray-500"
																		}
																	>
																		(${+plan.standardPrice * 12} / yr)
																	</span>
																</>
															) : (
																<span
																	className={
																		(checked
																			? "text-indigo-900"
																			: "text-indigo-900",
																		"font-medium")
																	}
																>
																	${plan.standardPrice} / yr
																</span>
															)}
														</RadioGroup.Description>
														<RadioGroup.Description
															className={classNames(
																checked ? "text-indigo-700" : "text-gray-500",
																"ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right"
															)}
														>
															{plan.limit}
														</RadioGroup.Description>
													</>
												)}
											</RadioGroup.Option>
										))}
									</div>
								</RadioGroup>
							</div>

							<div className="px-4 py-3 bg-white text-right sm:px-6">
								{alreadyPurchased ? (
									<>
										<p className="text-red-500">
											<strong>You are already a member.</strong>
											<br />{" "}
											<a
												className="bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
												href="mailto:nsw@ipweansw.org"
											>
												Contact us
											</a>
										</p>
									</>
								) : (
									<button
										type="submit"
										className={
											alreadyPurchased
												? "disabled bg-gray-400 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
												: "bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
										}
									>
										Confirm
									</button>
								)}
							</div>
						</div>
					</form>
				</section>
			) : (
				<div className="px-4 py-3 bg-white text-right sm:px-6">
					<p className="text-red-500">
						<a
							className="bg-primary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
							href="mailto:nsw@ipweansw.org"
						>
							Contact us
						</a>
					</p>
				</div>
			)}
		</Layout>
	)
}

const Header = ({ title }) => (
	<h1 className="text-xl md:text-2xl lg:text-4xl py-4 text-center lg:text-left text-neutral font-bold mx-2 lg:mx-20 xl:mx-28 mt-6">
		{title}
	</h1>
)

export default MembershipItem

export const pageQuery = graphql`
	query MemberPlansQuery($itemId: String!) {
		memberPlans(membershipPlanId: { eq: $itemId }) {
			membershipPlanId
			name
			standardPrice
			productId
			description
			scheduleType
			membershipType
		}
		allMemberPlans {
			nodes {
				name
				permalink
				productId
				scheduleType
				standardPrice
				membershipType
			}
		}
	}
`
